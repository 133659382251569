import React from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import { animation } from '@styles/vars/animation.style'

const InViewSection = ({ children }) => (
  <InView triggerOnce={true} rootMargin={animation.rootMargin}>
    {({ inView, ref }) => (
      <div ref={ref} data-inview={inView.toString()}>
        {React.cloneElement(children, { inView: inView })}
      </div>
    )}
  </InView>
)

InViewSection.propTypes = {
  threshold: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default InViewSection
