import React from 'react'
import PropTypes from 'prop-types'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  TextBody,
  TextBodySmall,
  TextBodyXSmall,
} from '../TextStyles'
import AnimateSlideIn from '../animation/AnimateSlideIn'
import {
  ListItem,
  OrderedList,
  RichTextMain,
  UnorderedList,
} from './index.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import PageTransitionLink from '../PageTransitionLink'
import InViewSection from '../InViewSection'
import { useStore } from '../../Store'
import Blockquote from '../TextStyles/Blockquote'

const RichText = ({
  content,
  delay = 0,
  maxWidth,
  animate = true,
  buildOn = true,
  paragraphSize = 'regular',
}) => {
  const [store] = useStore()
  const { showPageMask } = store

  const getContent = children =>
    buildOn ? (
      <AnimateSlideIn animate={animate} delay={delay}>
        {children}
      </AnimateSlideIn>
    ) : (
      children
    )

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.UNDERLINE]: text => <u>{text}</u>,
      [MARKS.CODE]: text => <code>{text}</code>,
    },
    renderNode: {
      /*
       ** Blocks
       */

      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children?.toString().trim() === '') return

        return (
          <>
            {paragraphSize === 'regular' && (
              <TextBody maxWidth={maxWidth}>{getContent(children)}</TextBody>
            )}

            {paragraphSize === 'small' && (
              <TextBodySmall maxWidth={maxWidth}>
                {getContent(children)}
              </TextBodySmall>
            )}

            {paragraphSize === 'xsmall' && (
              <TextBodyXSmall maxWidth={maxWidth}>
                {getContent(children)}
              </TextBodyXSmall>
            )}
          </>
        )
      },

      [BLOCKS.HEADING_1]: (node, children) => (
        <Heading1 as="h1" maxWidth={maxWidth}>
          {getContent(children)}
        </Heading1>
      ),

      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading2 as="h2" maxWidth={maxWidth}>
          {getContent(children)}
        </Heading2>
      ),

      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading3 as="h3" maxWidth={maxWidth}>
          {getContent(children)}
        </Heading3>
      ),

      [BLOCKS.HEADING_4]: (node, children) => (
        <Heading4 as="h4" maxWidth={maxWidth}>
          {getContent(children)}
        </Heading4>
      ),

      [BLOCKS.HEADING_5]: (node, children) => <h5>{getContent(children)}</h5>,

      [BLOCKS.HEADING_6]: (node, children) => <h6>{getContent(children)}</h6>,

      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedList>{children}</OrderedList>
      ),

      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),

      [BLOCKS.LIST_ITEM]: (node, children) => {
        return (
          <InViewSection>
            <ListItem animate={animate && !showPageMask} delay={delay}>
              {children}
            </ListItem>
          </InViewSection>
        )
      },

      [BLOCKS.HR]: () => <hr />,

      [BLOCKS.QUOTE]: (node, children) => <Blockquote>{children}</Blockquote>,

      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const { description, gatsbyImageData } = node.data.target

        return <GatsbyImage image={gatsbyImageData} alt={description} />
      },

      // [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {},

      /*
       ** Inlines
       */

      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),

      [INLINES.ASSET_HYPERLINK]: (node, children) => (
        <a href={node.data.target.file.url}>{children}</a>
      ),

      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <PageTransitionLink to={node.data.target.slug}>
          {children}
        </PageTransitionLink>
      ),

      // [INLINES.EMBEDDED_ENTRY]: (node, children) => {},
    },
    renderText: text =>
      React.Children.toArray(
        text.split('\n').map((t, i) =>
          i > 0 ? (
            <>
              <br />
              {t}
            </>
          ) : (
            t
          )
        )
      ),
  }

  return <RichTextMain>{renderRichText(content, options)}</RichTextMain>
}

RichText.propTypes = {
  content: PropTypes.object,
  delay: PropTypes.number,
  animate: PropTypes.bool,
  buildOn: PropTypes.bool,
  maxWidth: PropTypes.number,
  paragraphSize: PropTypes.oneOf(['regular', 'small', 'xsmall']),
}

export default RichText
