import { AnimateSlideInContent } from '@components/animation/AnimateSlideIn/index.style'
import styled from 'styled-components'
import { flow } from '../../styles/utils/functions.style'
import { easings } from '../../styles/vars/easings.style'
import { font } from '../../styles/vars/font.style'

export const RichTextMain = styled.div`
  ${flow(1.6)}

  strong,
  em,
  u,
  a {
    display: inline-block;
  }

  a {
    text-decoration: underline;
  }
`

export const UnorderedList = styled.ul`
  list-style: none;
`

export const OrderedList = styled.ol`
  counter-reset: ol;
  list-style: none;
`

export const ListItem = styled.div`
  ${AnimateSlideInContent} {
    position: relative;

    ul & {
      padding-left: 0.75em;
    }

    ol & {
      padding-left: 1.1em;
    }

    :before {
      counter-increment: ol;
      position: absolute;
      left: 0;
      transform: translateY(
        ${props => (props.inView && props.animate ? '0' : '3.2rem')}
      );
      display: block;
      overflow: hidden;
      opacity: ${props => (props.inView && props.animate ? 1 : 0)};
      transition: transform 0.3s ${easings.inOut.default},
        opacity 0.3s ${easings.inOut.default};
      transition-delay: ${props => `${props.delay + 0.05}s`};

      ul & {
        content: '';
        top: 0.7em;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        background-color: currentColor;
      }

      ol & {
        content: counter(ol) '.';
        top: 0;
        font-weight: ${font.primary.weight.regular};
      }
    }
  }

  & + & {
    margin-top: 0.5em;
  }
`
